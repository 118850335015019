<template>
  <div class="w100 h100 position-rel">
    <a-tabs v-model:activeKey="activeKey" class="w100 h100 jlyq_tabs popularize-tabs" ref="tabs">
      <template v-for="tab in tabs" :key="tab.key">
        <a-tab-pane :key="tab.key" v-if="showTab(tab.key)" :tab="tab.name">
          <div class="w100 h100 jlyq_advertise_wrapper popularize-advertise-wrapper">
            <div class="operation_btns">
              <a-space>
                <!-- 新建产品、绑定账户 -->
                <a-button type="primary" @click="btnOptions.fun" v-if="btnOptions">{{ btnOptions.text }}</a-button>
                <!-- 批量操作 -->
                <batch-operation :option-name="activeKey" :selected-rows="selectedRows" v-if="activeKey != 'product'" @operationChange="operationChange" @dailyBudgetChange="dailyBudgetChange"></batch-operation>
                <!-- <div class="host-box">
                  <span v-if="hostingList.length > 0" class="flexAlignCenter" @click="toHosting">
                    <svg-icon icon-class="hosting" class-name="hosting"></svg-icon>
                    <span class="host-box-content">{{ `有${hostingList.length}条${hostActiveTips[activeKey]}` }}</span>
                  </span>
                  <a-button v-else type="primary" style="margin-right: 12px" @click="toHosting">自定义托管</a-button>
                </div> -->
              </a-space>

              <a-space class="float-r">
                <span style="color: #c1c1c1; padding-right: 16px"><SyncOutlined style="color: #197afb; margin-right: 12px" @click="refreshData" />数据更新时间：{{ updateTime }}</span>
                <TempSelect :active-temp-name="activeTemp" :temp-list="tempList" @getTempName="getTempNameValue" @editTemp="editTemp" @deleteTemp="deleteTemp" @add-temp="addTemp" :disabled="tableLoading" />
                <!-- 这期不做 -->
                <a-button v-if="false">
                  <template #icon><CloudDownloadOutlined /></template> 导出Excel
                </a-button>
              </a-space>
            </div>
            <!-- 这期不做 -->
            <!-- <div class="operation_tags">
            <a-space>
              <a-tag> 起量账户 </a-tag>
              <a-tag> 起量账户 </a-tag>
              <a-tag> 起量账户 </a-tag>
            </a-space>
          </div> -->
            <!-- <div class="host-tag" v-if="showHostTag">
              <span class="host-tag-item item-yellow" v-if="hostingTags.budgetClost && hostingTags.budgetClost > 0">预算接近({{ hostingTags.budgetClost }})</span>
              <span class="host-tag-item item-orange" v-if="hostingTags.abnormalBid && hostingTags.abnormalBid > 0">出价异常({{ hostingTags.abnormalBid }})</span>
              <span class="host-tag-item item-red" v-if="hostingTags.abnormalTarget && hostingTags.abnormalTarget > 0">优化目标异常({{ hostingTags.abnormalTarget }})</span>
            </div> -->
            <jlyq-tabs-table v-if="activeKey == tab.key" v-model:selectedRows="selectedRows" v-model:activeKey="activeKey" v-model:loading="tableLoading" :columns="tableColumns" @switchChange="switchChange" ref="table" @updateFreshTime="time => (updateTime = time)" />
          </div>
        </a-tab-pane>
      </template>
    </a-tabs>
    <customize-column :selected-indicator="selectedIndicator" three-cloumn-key="id" :fixed-list="fixedList" v-model:column-name="editTempName" :menu-list="menuList" :modal-visible="modalVisible" :show-check="true" @get-temp-data="getTempData" @getDraggableList="getDraggableList" />
  </div>
</template>

<script>
import { CloudDownloadOutlined, SyncOutlined } from '@ant-design/icons-vue';
import CustomizeColumn from '../../components/customizeColumnModal.vue';
import TempSelect from '../../components/templateSelect.vue';
import BatchOperation from '../../components/BatchOperation.vue';
import JlyqTabsTable from './JlyqTabsTable.vue';
import { mapState } from 'vuex';
import _ from 'lodash';
import columns from '@/views/popularize/assets/jlyqColumns.js';
import { editTempFunc, deleteTempFunc, addTempFunc, getTempNameValueFunc, setFixedListFunc, setDefaultListFunc, getIndicatorTempListFunc, getIndicatorTempByNameFunc, getTempDataFunc, setColumnFunc, setShowTableColumnFunc } from '@/views/popularize/assets/tempOperate.js';
import { getIndicatorTempListTT, getIndicatorTempByNameTT, updateIndicatorTT, addIndicatorTT, deleteIndicatorTT, batchOperationTT, acquisitionEnabledTT, updateStrategyTT } from '@/api/popularize/jlyq/index.js';
export default {
  emits: ['selectTabs'],
  name: 'JlyqTabs',
  components: {
    TempSelect,
    CloudDownloadOutlined,
    CustomizeColumn,
    BatchOperation,
    JlyqTabsTable,
    SyncOutlined,
  },
  props: {
    filters: { type: Object, default: () => {} },
    allIndicator: { type: Array, default: () => [] },
  },
  provide() {
    return { getActiveKey: () => this.activeKey };
  },
  inject: ['getBaseFilter', 'getTrendData'],
  data() {
    return {
      baseFilter: {}, // 主页筛选条件
      updateTime: '-', // 数据更新时间
      activeKey: 'product', //当前选中tab
      fixedList: [],
      // tabs配置
      tabs: [
        { name: '产品', key: 'product', component: 'TabProduct' },
        { name: '账户', key: 'account', component: 'TabAccount' },
        { name: '广告组', key: 'campaign', component: 'TabManage' },
        { name: '计划', key: 'ad', component: 'TabAdvertise' },
        { name: '创意', key: 'creativity', component: 'TabIdea' },
      ],
      editBudgetList: ['推广计划日预算', '广告出价', '深度目标出价', '广告日预算'],
      showSwitch: ['campaign', 'ad', 'creativity'],
      // 表格列
      tableColumns: [],
      fixedOptions: columns.fixedOptions,
      tableLoading: false, //表格加载状态
      tableSetLoading: false,
      selectedRows: [], //选中的行
      allIndexDict: {}, //所有指标字典
      operatType: null,
      // 所有配置项
      menuList: [],
      // 模板列表
      tempList: [{ configKey: 'MATERIA2L', tempName: '自定义列表' }],
      // 选中的自定义列模板
      activeTemp: null,
      // 选中的自定义列模板
      editTempName: null,
      // 打开自定义列表弹窗
      modalVisible: false,
      // 已选指标项
      selectedIndicator: [],
      // 当前自定义列表指标项
      selectedSource: [],
      // 默认指标项
      indicatorSource: [],
      // 口径选择项
      tjkjList: [
        { key: 'REQUEST_TIME', title: '广告播放', details: ['转换数据统计至广告播放时间点', '适合场景:广告赔付考察、广告整体生命周期表现'] },
        { key: 'REPORTING_TIME', title: '转化回传', details: ['转换数据统计至行为上报时间点', '适合场景:实时查看当日上报数据情况'] },
      ],
      tjkjSelected: {
        selectedKey: null,
        selectedValue: null,
      },
      batchEditVisible: false, //批量修改弹框是否打开
      watchTimer: {},
      // 处于自定义托管中的数量
      hostingList: [123],
      hostActiveTips: {
        product: '产品自定义托管中',
        account: '账户自定义托管中',
        campaign: '广告组自定义托管中',
        ad: '计划自定义托管中',
        creativity: '创意自定义托管中',
      },
      // 自定义托管状态个数
      hostingTags: {
        budgetClost: 10,
        abnormalBid: 23,
        abnormalTarget: 25,
      },
    };
  },
  computed: {
    ...mapState({
      userId: state => state.oauth.userInfo.userId,
    }),
    showTab() {
      return tab => {
        return tab != 'creativity' || (tab == 'creativity' && tab == this.activeKey);
        return true;
      };
    },
    /**
     * @description: 是否显示自定义托管tag栏
     * @param {*}
     * @return {*}
     */
    showHostTag() {
      return (this.hostingTags.budgetClost && this.hostingTags.budgetClost > 0) || (this.hostingTags.abnormalBid && this.hostingTags.abnormalBid > 0) || (this.hostingTags.abnormalTarget && this.hostingTags.abnormalTarget > 0);
    },
    // 按钮配置 新建产品、绑定账户
    btnOptions() {
      const obj = {
        // product: {
        //   text: '新建产品',
        //   fun: () => {
        //     this.$router.push({ name: 'product' });
        //   },
        // },
        account: {
          text: '绑定账户',
          fun: () => {
            const ids = this.selectedRows.map(item => item.advertiser_id);
            this.$router.push({ name: 'mediaAccount', params: { ids, channel: 'GDT' } });
          },
        },
      };
      return obj[this.activeKey];
    },
  },
  watch: {
    // 筛选条件
    '$attrs.base-filter': {
      handler(newVal, oldVal) {
        clearTimeout(this.watchTimer);
        this.watchTimer = setTimeout(() => {
          this.baseFilter = newVal;
          this.$nextTick(() => {
            this.getTableData();
          });
        }, 100);
      },
      deep: true,
      immediate: true,
    },
    activeKey: {
      handler(newVal, oldVal) {
        this.tabsChange(newVal);
      },
    },
  },
  mounted() {
    const filterOptions = this.getBaseFilter();
    filterOptions.isNotData = this.activeKey == 'product' || this.activeKey == 'account';
  },
  methods: {
    async init() {
      this.setDefaultList();
      await this.getIndicatorTempList();
    },
    /**
     * @description: 前往自定义托管页面
     * @param {*}
     * @return {*}
     */
    toHosting() {
      this.$router.push({ name: 'hosting' });
    },
    /**
     * @description:单独起量
     * @param {*} changed
     * @param {*} record
     * @return {*}
     */
    async switchChange(changed, record) {
      const newItem = { campaign: 'campaign_id', ad: 'id', creativity: 'creative_id' };
      record.switchLoading = true;
      const rowkey = newItem[this.activeKey];
      let arr = [record[rowkey]];
      let res = await batchOperationTT(arr, changed ? 'enable' : 'disable', this.activeKey);
      if (res.code == 0) {
        switch (this.activeKey) {
          case 'campaign':
            if (record.campaign_status == '启用') {
              record.campaign_status = '暂停';
            } else {
              record.campaign_status = '启用';
            }
            break;
          case 'ad':
            if (record.opt_status == 'AD_STATUS_ENABLE') {
              record.opt_status = 'AD_STATUS_DISABLE';
            } else {
              record.opt_status = 'AD_STATUS_ENABLE';
            }
            break;
          case 'creativity':
            if (record.opt_status == 'CREATIVE_STATUS_ENABLE') {
              record.opt_status = 'CREATIVE_STATUS_DISABLE';
            } else {
              record.opt_status = 'CREATIVE_STATUS_ENABLE';
            }
            break;
        }
        this.$message.success('操作成功！');
      } else {
        this.$message.error('操作失败！');
      }
    },
    async dailyBudgetChange() {},
    /** 批量操作
     * @param {*} mode
     * @return {*}
     */
    async operationChange(mode, value) {
      let ids = [];
      if (this.activeKey.includes('campaign')) {
        //广告组
        ids = this.selectedRows.map(item => item.campaign_id);
      } else if (this.activeKey.includes('ad')) {
        //计划
        ids = this.selectedRows.map(item => item.id);
      } else if (this.activeKey.includes('creativity')) {
        //创意
        ids = this.selectedRows.map(item => item.creative_id);
      }
      // 批量一键起量
      if (mode == 'minimum') {
        let res = await acquisitionEnabledTT({ adgroupIds: ids, autoAcquisitionEnabled: value });
        // 批量调整出价策略
      } else if (mode == 'strategy') {
        let res = await updateStrategyTT({ adGroupIds: ids, bidStrategy: value });
      } else {
        // 批量编辑状态
        let res = await batchOperationTT(ids, mode, this.activeKey);
        if (res.code == 0) {
          this.$message.success('操作成功！');
        }
      }
    },
    /** 处理默认数据
     * @param {*} list
     * @return {*}
     */
    setDefaultList() {
      setDefaultListFunc(this);
    },
    /** 处理固定列表
     * @param {*} list
     * @return {*}
     */
    setFixedList() {
      setFixedListFunc(this);
    },
    /** 获取所有模板名称
     * @param {*}
     * @return {*}
     */
    async getIndicatorTempList() {
      getIndicatorTempListFunc(this, getIndicatorTempListTT, 'TT');
    },

    /** 是否为三级目录
     * @param {*} menu
     * @return {*}
     */
    isThreeLevelMenu(menu) {
      return menu?.info?.[0]?.info?.[0];
    },

    /** 获取选中的模板已选中指标
     * @param {*}
     * @return {*}
     */
    async getIndicatorTempByName(tempName, setColumn = true) {
      await getIndicatorTempByNameFunc(this, tempName, setColumn, getIndicatorTempByNameTT, 'TT');
    },
    /** 是否属于固定项
     * @param {*} menu
     * @return {*}
     */
    isBelongArray(menu) {
      let isBelong = this.fixedList.some(item => {
        return menu.id == item.id;
      });
      return isBelong;
    },

    /** 获取排序后的已选指标数组
     * @param {Array} list 已选指标数组
     * @return {*}
     */
    getDraggableList(list) {
      this.selectedIndicator = list;
    },
    /** 关闭弹窗
     * @param {*}
     * @return {*}
     */
    getTempData(tempUserCustoms, tempName) {
      getTempDataFunc(this, tempUserCustoms ? this.selectedIndicator : tempUserCustoms, tempName, addIndicatorTT, updateIndicatorTT, 'TT');
    },
    /** 点击下拉列表编辑
     * @param {String} tempName 模板名称
     * @return {*}
     */
    async editTemp(tempName) {
      editTempFunc(this, tempName);
    },
    /** 点击下拉列表删除
     * @param {String} tempName 模板名称
     * @return {*}
     */
    deleteTemp(tempName) {
      deleteTempFunc(this, tempName, deleteIndicatorTT, 'TT');
    },
    /** 新增自定义指标
     * @param {*}
     * @return {*}
     */
    addTemp() {
      addTempFunc(this);
    },
    /** 模板下拉变化
     * @param {*} tempName
     * @return {*}
     */
    getTempNameValue(tempName) {
      getTempNameValueFunc(this, tempName, 'TT');
    },
    /** 设置表格根据不同tab页显示对应列名
     * @param {*}
     * @return {*}
     */
    setShowTableColumn(array) {
      setShowTableColumnFunc(this, array);
    },
    /** 设置自定义列
     * @param {*}
     * @return {*}
     */
    setColumn(arr) {
      setColumnFunc(this, arr);
    },
    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions.filter(item => {
          return item[key] == columnValue;
        })[0];
        return returnOpt && returnOpt[text];
      }
    },
    // tab页改变
    tabsChange(key) {
      const filterOptions = this.getBaseFilter();
      this.selectedRows = [];
      filterOptions.isNotData = key == 'product' || key == 'account';
      this.$emit('selectTabs', key);
      this.setFixedList();
      if (this.selectedIndicator?.length > 0) {
        this.setShowTableColumn(this.selectedIndicator);
      } else {
        this.setShowTableColumn(this.indicatorSource);
      }
    },
    // 获取表格数据
    getTableData() {
      const params = {};
      this.$parent.formatDate(params);
      this.activeTemp = localStorage.activeTempTT || this.activeTemp;
      const filters = { ...this.baseFilter, ...params, tempName: this.activeTemp };
      this.$refs.table?.getTableData({ filters });
    },
    refreshData() {
      this.getTableData();
      this.getTrendData();
    },
  },
};
</script>